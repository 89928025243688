$wrp-font-family: "wrp-icons" !default;
$wrp-font-path: "~@skryv/bundle/theme/assets/icons" !default;

$wrp-icon-info-circle: "\f05a";
$wrp-icon-exclamation-triangle: "\f071";
$wrp-icon-warning: "\f071";
$wrp-icon-expand: "\f065";
$wrp-icon-compress: "\f066";
$wrp-icon-file: "\e906";
$wrp-icon-binnenrenovatie: "\e900";
$wrp-icon-buitenschrijnwerk: "\e901";
$wrp-icon-dakrenovatie: "\e902";
$wrp-icon-gascondensatieketel: "\e903";
$wrp-icon-muurrenovatie: "\e904";
$wrp-icon-technische-installaties: "\e905";
$wrp-icon-vloerrenovatie: "\e907";
$wrp-icon-warmtepomp: "\e908";
$wrp-icon-warmtepompboiler: "\e909";
$wrp-icon-zonneboiler: "\e90a";
